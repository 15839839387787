import { fixAssetUrl } from '@features/contentful/helpers/assetHelpers';
import { KnowledgeArticle } from '@generated/graphql';
import { Article } from 'schema-dts';
import { Adapter } from './adapter';

export class ArticleAdapter implements Adapter<KnowledgeArticle, Article> {
    adapt(input: KnowledgeArticle): Article {
        return {
            '@type': 'BlogPosting',
            headline: input.title,
            url: `/knowledge/${input.urlSlug}`,
            author: {
                '@type': 'Person',
                name: input.author.fullName,
            },
            image: [
                fixAssetUrl(
                    `${input.featuredImageSquare?.url}?fit=fill&w=1600&h=1600&fm=jpg`
                ), //1:1
                fixAssetUrl(
                    `${input.featuredImageSquare?.url}?fit=fill&w=1600&h=1200&fm=jpg`
                ), //4:3
                fixAssetUrl(
                    `${input.featuredImageSquare?.url}?fit=fill&w=1600&h=900&fm=jpg`
                ), //16:9
            ],
            datePublished: input.sys.firstPublishedAt,
            dateModified: input.sys.publishedAt,
        };
    }
}
