import { Adapter } from '@features/seo/adapters/adapter';
import { BreadcrumbList, ListItem } from 'schema-dts';

export interface BreadCrumbItem {
    name: string;
    url: string;
}

export class BreadcrumbListAdapter
    implements Adapter<BreadCrumbItem[], BreadcrumbList>
{
    adapt(input: BreadCrumbItem[]): BreadcrumbList {
        return {
            '@type': 'BreadcrumbList',
            itemListElement: this.generateItemList(input),
        };
    }

    generateItemList(items: BreadCrumbItem[]): ListItem[] {
        return items.map((data, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: data.name,
            item: data.url,
        }));
    }
}
