import { MetaDefinition } from '@angular/platform-browser';
import { DjangoFactory } from './django-factory';
import { DjangoModel } from './django-model';

class MetaTag extends DjangoModel {
    attribute: string;
    attributeData: string;
    content: string;

    automaticDeserializeFields = ['attribute', 'attributeData', 'content'];

    get metaDefinition(): MetaDefinition {
        return {
            [this.attribute]: this.attributeData,
            content: this.content,
        };
    }
}

class PageSchema extends DjangoModel {
    schema: any;

    automaticDeserializeFields = ['schema'];
}

export class SeoMetadata extends DjangoModel {
    title: string;
    metaTags: MetaTag[];
    pageSchemas: PageSchema[];

    automaticDeserializeFields = ['title'];

    deserialize(input: any) {
        super.deserialize(input);
        this.metaTags = DjangoFactory.buildMany(MetaTag, input.meta_tags);
        this.pageSchemas = DjangoFactory.buildMany(
            PageSchema,
            input.page_schemas
        );
    }
}
