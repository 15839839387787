import { Adapter } from '@features/seo/adapters/adapter';
import { YoutubeVideo } from '@generated/graphql';
import { VideoObject } from 'schema-dts';

export class YoutubeVideoAdapter implements Adapter<YoutubeVideo, VideoObject> {
    adapt(input: YoutubeVideo): VideoObject {
        return {
            '@type': 'VideoObject',
            name: input?.title,
            description: input?.title,
            thumbnailUrl: `https://i.ytimg.com/vi/${this.getVideoId(
                input?.videoUrl
            )}/sddefault.jpg`,
            uploadDate: input?.sys.publishedAt,
            embedUrl: `https://www.youtube-nocookie.com/embed/${this.getVideoId(
                input?.videoUrl
            )}`,
        };
    }

    getVideoId(videoUrl: string) {
        const urlParams = new URLSearchParams(videoUrl || '');
        return urlParams.get('https://www.youtube.com/watch?v');
    }
}
