import { ProductAdapter } from '@features/seo/adapters/product.adapter';
import { Review } from '@generated/graphql';
import { Review as SchemaReview } from 'schema-dts';
import { Adapter } from './adapter';

export class ReviewAdapter implements Adapter<Review, SchemaReview> {
    adapt(input: Review): SchemaReview {
        const productAdapter = new ProductAdapter();

        return {
            '@type': 'Review',
            reviewRating: {
                '@type': 'Rating',
                ratingValue: input.rating,
                bestRating: 5,
                worstRating: 1,
            },
            author: {
                '@type': 'Person',
                name: input.name,
            },
            datePublished: input.date,
            reviewBody: input.body,
            itemReviewed: productAdapter.adaptFromReview(input),
        };
    }
}
